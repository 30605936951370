export const categories = {
  GET_ALL_CATEGORIES_REQUSET: "GET_ALL_CATEGORIES_REQUSET",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FALI: "GET_ALL_CATEGORIES_FALI",
  CLEAR_ALL_CATEGORIES: "CLEAR_ALL_CATEGORIES",

  GET_CATEGORIES_PRODUCT_REQUSET: "GET_CATEGORIES_PRODUCT_REQUSET",
  GET_CATEGORIES_PRODUCT_SUCCESS: "GET_CATEGORIES_PRODUCT_SUCCESS",
  GET_CATEGORIES_PRODUCT_FALI: "GET_CATEGORIES_PRODUCT_FALI",
};
