import { categories } from "../constant/categori-constant";

export const categoriesReducer = (
  state = { loading: true, categori: [] },
  action
) => {
  switch (action.type) {
    case categories.GET_ALL_CATEGORIES_REQUSET:
      return { loading: true };

    case categories.GET_ALL_CATEGORIES_SUCCESS:
      return { loading: false, categori: action.payload };

    case categories.GET_ALL_CATEGORIES_FALI:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getCategoriesProductReducer = (
  state = { loading: true, categoriProduct: [] },
  action
) => {
  switch (action.type) {
    case categories.GET_CATEGORIES_PRODUCT_REQUSET:
      return { loading: true };

    case categories.GET_CATEGORIES_PRODUCT_SUCCESS:
      return { loading: false, categoriProduct: action.payload };

    case categories.GET_CATEGORIES_PRODUCT_FALI:
      return { loading: false, error: action.payload };
    case categories.CLEAR_ALL_CATEGORIES:
      console.log("sss")
      return { loading: false, categoriProduct: [] };

    default:
      return state;
  }
};
