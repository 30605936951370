import { HomeConst } from "../constant/home-constant";
import { _http } from "../../helper/httpServices";
import { ERROR } from "../../feature/alerts";

export const getHomeData = () => async (dispatch) => {
    dispatch({ type: HomeConst.GET_HOME_REQUSET });

    await _http("home", { method: "get" })
        .then((res) => {
            dispatch({ type: HomeConst.GET_HOME_SUCCESS, payload: res.data });
        })
        .catch((err) => {
            let message;
            if (err?.response?.data?.message) message = err.response.data.message;
            ERROR(message);
            dispatch({ type: HomeConst.GET_HOME_FAIL, payload: err });
        });
};

export const getSearchProduct = (searchVal) => async (dispatch) => {
    if (searchVal.length === 0) {
        dispatch({ type: HomeConst.GET_SEARCHPRODUCT_SUCCESS, payload: [] });
    }
    else {
        dispatch({ type: HomeConst.GET_SEARCHPRODUCT_REQUEST })
        await _http(`/search`, { method: "get", params: { search: searchVal } })
            .then((res) => {
                dispatch({ type: HomeConst.GET_SEARCHPRODUCT_SUCCESS, payload: res.data.data });


            })
            .catch((err) => {

                let message;
                if (err?.response?.data?.message) message = err.response.data.message;
                ERROR(message);
                dispatch({ type: HomeConst.GET_SEARCHPRODUCT_FAIL, payload: err });
            });
    }
}
